import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ApiRouting } from "@globals/classes/ApiRouting";
import { BiCountryId } from "@globals/enums/BiLanguageAndCountryId";
import { AddressDto } from "@globals/openapi-models/model/addressDto";
import { AddressModel } from "@globals/openapi-models/model/addressModel";
import { CompanyRegistrationDto } from "@globals/openapi-models/model/companyRegistrationDto";
import { EntryAddressStatusDetails } from "@globals/openapi-models/model/entryAddressStatusDetails";
import { GetAddressInfoQuery } from "@globals/openapi-models/model/getAddressInfoQuery";
import { MessageStatusDetailsReadModel } from "@globals/openapi-models/model/messageStatusDetailsReadModel";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class BiAddressServiceBase {
  protected http: HttpClient;
  constructor() {
    this.http = inject(HttpClient);
  }

  /**
   * Returns all phone numbers and person names existing on an address were at least house number or meters is specified
   */
  public getAddressContactsInfo(queryObject: GetAddressInfoQuery): Observable<AddressDto[]> {
    return this.http.post<AddressModel[]>(ApiRouting.addressEndpoints.post.getAddressesWithContactInfo, queryObject);
  }

  public getCompaniesOnAddress(queryObject: GetAddressInfoQuery): Observable<CompanyRegistrationDto[]> {
    return this.http.post<CompanyRegistrationDto[]>(ApiRouting.addressEndpoints.post.getCompaniesOnAddress, queryObject);
  }

  /**
   *  Return the Address When a Marker is clicked on the Status Map.
   */
  public getStatusForEntryAddress(street: string, number: number, letter: string, zipCode: number, smsGroupId: number) {
    const params = { street, number, letter, zipCode, smsGroupId };
    return this.http.get<EntryAddressStatusDetails[]>(ApiRouting.smsGroupStatusRoutes.get.getStatusForEntryAddress, { params: params });
  }

  /**
   * @param kvhx Get status details of the messages/smslogs associated with the provided kvhx and SmsGroup Id
   */
  public getStatusForMessagesAsync(kvhx: string, smsGroupId: number) {
    return this.http.get<MessageStatusDetailsReadModel[]>(ApiRouting.smsGroupStatusRoutes.get.getStatusForMessages, { params: { kvhx, smsGroupId } });
  }

  /**
   * Returns all addresses existing under a house number.
   */
  public getAddresses(countryId: BiCountryId, zipcode: number, streetName?: string, streetId?: number, number?: number) {
    const params = { countryId, zipcode, streetId, streetName, number };

    return this.http.get<AddressModel[]>(ApiRouting.addressEndpoints.get.getAddresses, { params: params });
  }

  public getCityName(countryId: BiCountryId, zip: number) {
    return this.http.get<string>(ApiRouting.addressEndpoints.get.getCityName, { params: { countryId, zip } });
  }
}
