import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { EnrollmentService } from "@core-sub/services/enrollment.service";
import { BiTranslateService } from "@globals/bi-translate";
import { BiCountryId } from "@globals/enums/BiLanguageAndCountryId";
import { SenderExt } from "@models-sub/ext/SenderExt";
import differenceWith from "lodash-es/differenceWith";
import { AutoComplete, AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { Observable, map, of } from "rxjs";

@Component({
  selector: "en-sender-search",
  templateUrl: "./en-sender-search.component.html",
  styles: ["::ng-deep .p-autocomplete-loader{margin-right: 20px;}"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnSenderSearchComponent {
  @ViewChild("senderSearch") senderSearchControl: AutoComplete;
  @Output() onSenderSelected = new EventEmitter<SenderExt>();
  @Output() onSendATipClicked = new EventEmitter<any>();
  @Input() existingSenders: Array<SenderExt>;
  @Input() countryId: BiCountryId = BiCountryId.Default;
  @Input() kvhx: string;
  @Input() title: string;
  @Input() ariaLabel: string;
  @Input() ariaLabelledBy: string;

  constructor(
    private enrollmentService: EnrollmentService,
    private translator: BiTranslateService
  ) {}

  public suggestionsSender$: Observable<SenderExt[]> = of([]);

  public autoCompleteSenderSearch(e: AutoCompleteCompleteEvent) {
    const callback = senders => {
      if (this.senderSearchControl) this.senderSearchControl.loading = true; // There is some bug in the Prime Ng AutopComplete cusing this flag not to be correctly updated.
      if (senders.length === 0) {
        return [
          {
            id: "0",
            name: this.translator.instant("enrollment.NoResultsFound")
          }
        ];
      } else {
        return senders;
      }
    };

    if (!this.existingSenders) this.suggestionsSender$ = this.enrollmentService.searchSenders(this.countryId, this.kvhx, e.query as string).pipe(map(callback));
    else {
      this.suggestionsSender$ = this.enrollmentService.searchSenders(this.countryId, this.kvhx, e.query as string).pipe(
        map(result => {
          return differenceWith(result, this.existingSenders, (sender, exsistingSender) => sender.id === exsistingSender.id);
        }),
        map(callback)
      );
    }
  }

  public senderSelected(e?: SenderExt) {
    if (e && e.id !== "0") {
      this.onSenderSelected.emit(e);
      this.senderSearchControl.modelValue.set(null);
    } else {
      this.senderSearchControl.writeValue("");
    }
  }

  public sendATipClicked(e: Event) {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
    this.onSendATipClicked.emit(e);
    this.senderSearchControl.writeValue("");
    this.senderSearchControl.hide();
  }

  public scrollOneDown(e: Event & { overlay?: HTMLElement }) {
    if (e?.overlay) e.overlay.getElementsByClassName("p-autocomplete-panel")[0].scrollTop = 1;
  }
}
