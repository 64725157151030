export class ApiRouting {
  private static baseEndpoint = "/api/";

  private static baseAddressEndpoint = this.baseEndpoint + "Address/";
  private static baseSearchEndpoint = this.baseEndpoint + "Search/";
  private static baseSubscribeModuleEndpoint = this.baseEndpoint + "SubscribeModule/";
  private static baseCommonEndpoint = this.baseEndpoint + "Common/";
  private static baseStdReceiverEndpoint = this.baseEndpoint + "StandardReceiver/";
  private static baseWebMessageEndpoint = this.baseEndpoint + "WebMessage/";
  private static baseQuickResponseEndpoint = this.baseEndpoint + "QuickResponse/";
  private static baseSmsGroupStatusEndpoint = this.baseEndpoint + "SmsGroupStatus/";

  public static addressEndpoints = {
    get: {
      getAddresses: this.baseAddressEndpoint + "GetAddresses",
      getCityName: this.baseAddressEndpoint + "GetCityName"
    },
    post: {
      getAddressesWithContactInfo: this.baseAddressEndpoint + "GetAddressesWithContactInfo",
      getCompaniesOnAddress: this.baseAddressEndpoint + "GetCompaniesOnAddress"
    }
  };

  public static searchEndpoints = {
    get: {
      getStreetNames: this.baseSearchEndpoint + "GetStreetNames"
    }
  };

  public static subscribeModuleEndpoints = {
    get: {
      getSubscriptions: this.baseSubscribeModuleEndpoint + "GetSubscriptions",
      getSubscriptionModuleModel: this.baseSubscribeModuleEndpoint + "GetSubscriptionModuleModel"
    },
    post: {
      subscribeAddress: this.baseSubscribeModuleEndpoint + "SubscribeAddress",
      subscribeSupplyNumber: this.baseSubscribeModuleEndpoint + "SubscribeSupplyNumber",
      unsubscribeSupplyNumber: this.baseSubscribeModuleEndpoint + "UnsubscribeSupplyNumber",
      unsubscribeAddress: this.baseSubscribeModuleEndpoint + "UnsubscribeAddress",
      unsubscribeEnrollmentById: this.baseSubscribeModuleEndpoint + "UnsubscribeEnrollmentById"
    },
    verifyPinCode: this.baseSubscribeModuleEndpoint + "VerifyPinCode",

    requestPinCodeAttempt: this.baseSubscribeModuleEndpoint + "RequestPinCodeAttempt"
  };

  public static commonEndpoints = {
    validateGoogleCaptchaToken: this.baseCommonEndpoint + "ValidateGoogleCaptchaToken"
  };

  public static stdReceiverEndpoints = {
    get: {
      getStdReceiverSubscriptions: this.baseStdReceiverEndpoint + "GetStdReceiverSubscriptions", // Subscription Module
      getSubscriptionModuleModel: this.baseStdReceiverEndpoint + "GetSubscriptionModuleModel" // Subscription Module
    },
    post: {
      subscribeToGroups: this.baseStdReceiverEndpoint + "SubscribeToGroups", // Subscription Module
      unsubscribeGroups: this.baseStdReceiverEndpoint + "UnsubscribeGroups", // Subscription Module
      sendSubscriptionGroupsReceipt: this.baseStdReceiverEndpoint + "SendSubscriptionGroupsReceipt"
    }
  };

  public static webMessageEndPoints = {
    get: {
      getWebMessages: ApiRouting.baseWebMessageEndpoint + "GetWebMessages"
    }
  };

  public static quickResponseRoutes = {
    get: {
      getResponseStatistics: ApiRouting.baseQuickResponseEndpoint + "GetSmsGroupResponseStatistics",
      getRespondentsData: ApiRouting.baseQuickResponseEndpoint + "GetRespondentsData",
      getResponsePageData: ApiRouting.baseQuickResponseEndpoint + "GetResponsePageData"
    },
    post: {
      respond: ApiRouting.baseQuickResponseEndpoint + "Respond"
    }
  };

  public static smsGroupStatusRoutes = {
    get: {
      getStatusForEntryAddress: this.baseSmsGroupStatusEndpoint + "GetStatusForEntryAddress",
      getStatusForMessages: this.baseSmsGroupStatusEndpoint + "GetStatusForMessages"
    }
  };
}
