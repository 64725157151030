import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BiCountryId } from "@globals/enums/BiLanguageAndCountryId";
import { cloneObject, removeItemAtImmutable } from "@globals/helper-functions";
import { EnrollmentAddressDto } from "@globals/openapi-models/model/enrollmentAddressDto";
import { EnrollmentDto } from "@globals/openapi-models/model/enrollmentDto";
import { EnrollmentInitialDataDto } from "@globals/openapi-models/model/enrollmentInitialDataDto";
import { MySendersModel } from "@globals/openapi-models/model/mySendersModel";
import { OperatorInfoDto } from "@globals/openapi-models/model/operatorInfoDto";
import { PageNames } from "@globals/openapi-models/model/pageNames";
import { SenderExt } from "@models-sub/ext/SenderExt";
import { SignUpEnrolleeDtoExt } from "@models-sub/ext/SignUpEnrolleeDtoExt";
import { ApiRoutesEn } from "@shared-sub/ApiRoutesEn";
import sortBy from "lodash-es/sortBy";
import { BehaviorSubject, tap } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class EnrollmentService {
  private mySendersModel = new BehaviorSubject<MySendersModel>(undefined);
  public mysendersModel$ = this.mySendersModel.asObservable();
  public accessToken: string;

  constructor(private http: HttpClient) {}

  public getAddresses(countryId: BiCountryId, zipCode: number, streetId: number) {
    const params = {
      countryId,
      zipCode,
      streetId
    };
    return this.http.get<EnrollmentAddressDto[]>(ApiRoutesEn.enrollmentRoutes.get.getAddresses, { params: params });
  }

  /**
   * Requests the country ID based on the host name (as we cannot be sure about that here on client side).
   */
  public getInitialData() {
    return this.http.get<EnrollmentInitialDataDto>(ApiRoutesEn.enrollmentRoutes.get.getInitialData).pipe(
      tap(data => {
        this.accessToken = data.accessToken;
      })
    );
  }

  public getOperatorInfo(countryId: BiCountryId) {
    return this.http.get<OperatorInfoDto>(ApiRoutesEn.enrollmentRoutes.get.getOperatorInfo, {
      params: { countryId }
    });
  }

  public getSenderBySenderId(senderId: string) {
    const params: { [key: string]: string } = {
      senderId
    };
    return this.http.get<SenderExt>(ApiRoutesEn.enrollmentRoutes.get.getSenderBySenderId, { params: params });
  }

  public getSenderBySlug(slug: string) {
    const params = { slug };

    return this.http.get<SenderExt>(ApiRoutesEn.enrollmentRoutes.get.getSenderBySlug, { params });
  }

  public getRelevantSenders(countryId: BiCountryId, municipalityCode: number, kvhx: string, senderId: string) {
    const params: { [key: string]: string } = {
      countryId: countryId.toString(),
      municipalityCode: municipalityCode.toString(),
      kvhx
    };

    if (senderId) params.senderId = senderId;

    return this.http.get<SenderExt[]>(ApiRoutesEn.enrollmentRoutes.get.getRelevantSenders, { params: params });
  }

  public searchSenders(countryId: BiCountryId, kvhx: string, search: string) {
    const params = {
      countryId,
      kvhx,
      search
    };
    return this.http.get<SenderExt[]>(ApiRoutesEn.enrollmentRoutes.get.searchSenders, { params: params });
  }

  public signUp(signUpInfo: SignUpEnrolleeDtoExt) {
    return this.http.post(ApiRoutesEn.enrollmentRoutes.signUp, signUpInfo);
  }

  public sendATip(pageNameId: PageNames, message: string, kvhx: string) {
    return this.http.post(ApiRoutesEn.enrollmentRoutes.sendATip, { pageNameId, message, kvhx });
  }

  public addEnrollments(kvhx: string, senders: Array<SenderExt>) {
    // Get all selected senders (unblocked) and map to dtos
    const dtos = senders.map(s => <EnrollmentDto>{ senderId: s.id, kvhx: kvhx });

    return this.http.post(ApiRoutesEn.enrollmentRoutes.create.addEnrollments, dtos).pipe(
      tap(() => {
        const stateClone = cloneObject(this.mySendersModel.value);
        if (stateClone) {
          // Make sure the "selected" state is set false as this value is only intended to control the checkbox's value.
          const addressAndSenders = stateClone.enrollmentAddressesWithSenders.find(eaws => eaws.address.kvhx === kvhx);

          senders.forEach(s => {
            addressAndSenders.senders.push({ ...s, selected: false });
          });

          addressAndSenders.senders = sortBy(addressAndSenders.senders, x => x.name);
          this.mySendersModel.next(stateClone);
        }
      })
    );
  }

  /**
   *  Returns model to be used for My senders/main page where multiple data is needed
   */
  public initMySendersModel() {
    return this.http.get<MySendersModel>(ApiRoutesEn.enrollmentRoutes.get.getMySendersModel).pipe(
      tap(model => {
        this.mySendersModel.next(model);
      })
    );
  }

  public clearMySendersModel() {
    this.mySendersModel.next(undefined);
  }

  public deleteEnrollment(kvhx: string, senderId: string) {
    return this.http
      .delete(ApiRoutesEn.enrollmentRoutes.delete.deleteEnrollment, {
        body: { kvhx, senderId }
      })
      .pipe(
        tap(() => {
          const stateClone = {
            ...this.mySendersModel.value,
            enrollmentAddressesWithSenders: this.mySendersModel.value.enrollmentAddressesWithSenders.map(ea =>
              ea.address.kvhx === kvhx
                ? {
                    ...ea,
                    senders: ea.senders.filter(s => s.id !== senderId)
                  }
                : ea
            )
          };

          this.mySendersModel.next(stateClone);
        })
      );
  }

  public addEnrolleeAddress(enrolleeAddress: EnrollmentAddressDto) {
    const dto = {
      locationName: enrolleeAddress.locationName ? enrolleeAddress.locationName : "",
      kvhx: enrolleeAddress.kvhx
    };

    return this.http.post(ApiRoutesEn.enrollmentRoutes.addEnrolleeAddress, dto).pipe(
      tap(() => {
        this.mySendersModel.next({
          enrolleeInfo: this.mySendersModel.value.enrolleeInfo,
          enrollmentAddressesWithSenders: [
            ...this.mySendersModel.value.enrollmentAddressesWithSenders,
            {
              address: { ...enrolleeAddress },
              senders: []
            }
          ]
        });
      })
    );
  }

  public deleteEnrolleeAddress(enrolleeAddressKvhx: string) {
    return this.http
      .delete(ApiRoutesEn.enrollmentRoutes.delete.deleteEnrolleeAddress, {
        params: { kvhx: enrolleeAddressKvhx }
      })
      .pipe(
        tap(() => {
          const index = this.mySendersModel.value.enrollmentAddressesWithSenders.findIndex(eaws => eaws.address.kvhx === enrolleeAddressKvhx);
          if (index > -1) {
            this.mySendersModel.next({
              enrolleeInfo: this.mySendersModel.value.enrolleeInfo,
              enrollmentAddressesWithSenders: removeItemAtImmutable(this.mySendersModel.value.enrollmentAddressesWithSenders, index)
            });
          }
        })
      );
  }

  /**
   * Updates only the name of a enrollee address
   */
  public updateEnrolleeAddressName(newName: string, kvhx: string) {
    return this.http.patch(ApiRoutesEn.enrollmentRoutes.update.updateEnrolleeAddressName, {}, { params: { newName, kvhx } }).pipe(
      tap(() => {
        const stateClone = cloneObject(this.mySendersModel.value);
        const address = stateClone.enrollmentAddressesWithSenders.find(eaws => eaws.address.kvhx === kvhx)?.address;
        if (address) {
          address.locationName = newName;
          this.mySendersModel.next(stateClone);
        }
      })
    );
  }

  public getSenderLogo(senderId: string) {
    const params = {
      senderId
    };

    return this.http.get(ApiRoutesEn.enrollmentRoutes.get.getSenderLogo, {
      params: params,
      responseType: "arraybuffer"
    });
  }
}
