//#region Phone numbers
export const phoneLength_DK = 8;
export const phoneLength_NO = 8;
export const phoneLength_EN = 10;

/**
 * Tuple with [min,max]
 */
export const phoneLength_SE = [9, 15];
export const phoneLength_FI = [6, 12];
export const phoneLength_DE = [7, 11];

/**
 * Tuple with [min,max]
 */
export const landLineLength_SE = [7, 10];

/**
 * Tuple with [min,max]
 */
export const landLineLength_FI = [8, 10];

export const landLineLength_EN = [6, 10];
//#endregion

export const maxLengthStreet = 200;

/// Google api \\\
export const googleApiKey = "AIzaSyC8KQfH5i634WXVSBOmC0XA_iV2cSYYf1Y";

/**
 * Number of minutes before a PIN-code can be rerequested
 */
export const minBeforePinCanBeRerequested = 5;
